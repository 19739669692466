<template>
  <!-- 地图弹窗 -->
  <el-dialog
    title="地图选点"
    :close-on-click-modal="false"
    :visible.sync="dialogMapVisible"
    :show-close="false"
  >
    <div class="amap-page-container">
      <el-amap vid="amapDemo" :center="center" :map-manager="amapManager" class="amap-demo">
        <el-amap-marker
          v-for="marker in markers"
          :position="marker.position"
          :events="marker.events"
          :visible="marker.visible"
          :draggable="marker.draggable"
          :key="marker.position[0]"
        ></el-amap-marker>
      </el-amap>
      <div class="map_bottom">
        <div class="search">
          <el-input v-model="city" style="width: 170px; margin-right: 5px"></el-input>
          <el-button type="primary" @click="searchMap">{{ $t('button.search') }}</el-button>
        </div>
        <div class="lnglat">
          已选择的经纬度:
          <el-input :value="formInline.hasSelect" style="width: 170px"></el-input>
        </div>
        <div class="map_button">
          <el-button type="primary" @click="mapSure">确定</el-button>
          <el-button @click="mapCancel">取消</el-button>
        </div>
        <div style="clear: both"></div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
export default {
  data() {
    return {
      amapManager,
      city: "",
      dialogMapVisible: false,
      zoom: 16,
      center: [114.47, 36.6],
      markers: [
        {
          position: [114.520932, 38.050882],
          events: {
            click: (eventName, handler, context) => {
              // 逆地理编码
              var lnglatXY = [eventName.lnglat.lng, eventName.lnglat.lat]; // 地图上所标点的坐标
              this.temLng = eventName.lnglat.lng;
              this.temLat = eventName.lnglat.lat;
              this.formInline.hasSelect = lnglatXY.join(",");
              this.geocoder.getAddress(lnglatXY, (status, result) => {
                if (status === "complete" && result.info === "OK") {
                  // 获得了有效的地址信息:
                  // 即，result.regeocode.formattedAddress
                  this.formInline.parkAddress = result.regeocode.formattedAddress;
                } else {
                  // 获取地址失败
                  this.$alert("fail", this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine'),
                  });
                }
              });
            },
            dragend: (e) => {
              this.markers[0].position = [e.lnglat.lng, e.lnglat.lat];
            },
          },
          visible: true,
          draggable: false,
        },
      ],
      windows: [],
      temLng: "",
      temLat: "",
      temAddress: "",
      formInline: {
        hasSelect: "",
        parkAddress: "",
      },
    };
  },
  methods: {
    mapCancel() {
      this.$emit("mapCancel");
    },
    mapSure() {
      let opt = {
        temLat: this.temLat,
        temLng: this.temLng,
        hasSelect: this.formInline.hasSelect,
        parkAddress: this.formInline.parkAddress,
      };
      this.$emit("mapSure", opt);
    },
    mapClose() {
      this.dialogMapVisible = false;
    },
    //   mapOpen () {
    //       this.dialogMapVisible = true;
    //   },
    searchMap() {
      var placeSearch = new AMap.PlaceSearch({
        // 构造地点查询类
        pageSize: 5,
        pageIndex: 1,
        // city: "石家庄" // 城市
      });
      placeSearch.search(this.city, (status, result) => {
        this.onSearchResult(result.poiList.pois);
      });
    },
    openMap() {
      this.dialogMapVisible = true;
      setTimeout(() => {
        let map = amapManager.getMap();
        this.map = map;
        map.on("click", (e) => {
          let lng = e.lnglat.getLng();
          let lat = e.lnglat.getLat();
          var lnglatXY = [lng, lat]; // 地图上所标点的坐标
          this.temLng = lng;
          this.temLat = lat;
          // 地图点击添加聚焦点
          this.markers = [];
          this.markers.push({
            position: [lng, lat],
            events: {
              click: (eventName, handler, context) => {
                // 逆地理编码
                var lnglatXY = [eventName.lnglat.lng, eventName.lnglat.lat]; // 地图上所标点的坐标
                this.temLng = eventName.lnglat.lng;
                this.temLat = eventName.lnglat.lat;
                this.formInline.hasSelect = lnglatXY.join(",");
                this.geocoder.getAddress(lnglatXY, (status, result) => {
                  if (status === "complete" && result.info === "OK") {
                    // 获得了有效的地址信息:
                    // 即，result.regeocode.formattedAddress
                    this.formInline.parkAddress = result.regeocode.formattedAddress;
                    this.temAddress = result.regeocode.formattedAddress;
                  } else {
                    // 获取地址失败
                    this.$alert("fail", this.$t('pop_up.Tips'), {
                      confirmButtonText: this.$t('pop_up.Determine'),
                    });
                  }
                });
              },
              dragend: (e) => {
                this.markers[0].position = [e.lnglat.lng, e.lnglat.lat];
              },
            },
          });

          this.formInline.hasSelect = lnglatXY.join(",");
          this.geocoder.getAddress(lnglatXY, (status, result) => {
            if (status === "complete" && result.info === "OK") {
              // 获得了有效的地址信息:
              // 即，result.regeocode.formattedAddress
              this.formInline.parkAddress = result.regeocode.formattedAddress;
              this.temAddress = result.regeocode.formattedAddress;
            } else {
              // 获取地址失败
              this.$alert("fail", this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
          // document.getElementById("lnglat").value = e.lnglat.getLng() + ',' + e.lnglat.getLat()
        });
      }, 1000);
    },
    onSearchResult(pois) {
      let latSum = 0;
      let lngSum = 0;
      this.markers = [];
      this.map.setZoom(13);
      if (pois.length > 0) {
        pois.forEach((poi) => {
          let { lng, lat } = poi.location;
          lngSum += lng;
          latSum += lat;
          this.markers.push({
            position: [lng, lat],
            events: {
              click: (eventName, handler, context) => {
                // 逆地理编码
                var lnglatXY = [eventName.lnglat.lng, eventName.lnglat.lat]; // 地图上所标点的坐标
                this.temLng = eventName.lnglat.lng;
                this.temLat = eventName.lnglat.lat;
                this.formInline.hasSelect = lnglatXY.join(",");
                this.geocoder.getAddress(lnglatXY, (status, result) => {
                  if (status === "complete" && result.info === "OK") {
                    // 获得了有效的地址信息:
                    // 即，result.regeocode.formattedAddress
                    this.formInline.parkAddress = result.regeocode.formattedAddress;
                    this.temAddress = result.regeocode.formattedAddress;
                  } else {
                    // 获取地址失败
                    this.$alert("fail", this.$t('pop_up.Tips'), {
                      confirmButtonText: this.$t('pop_up.Determine'),
                    });
                  }
                });
              },
              dragend: (e) => {
                this.markers[0].position = [e.lnglat.lng, e.lnglat.lat];
              },
            },
          });
        });
        let center = {
          lng: lngSum / pois.length,
          lat: latSum / pois.length,
        };
        this.map.setCenter([center.lng, center.lat]);
      }
    },
  },
  mounted() {
    let _this = this;
    this.$nextTick(function () {
      setTimeout(() => {
        AMap.service("AMap.Geocoder", function () {
          // 回调函数
          // 实例化Geocoder
          _this.geocoder = new AMap.Geocoder({
            city: "010", // 城市，默认：“全国”
          });
        });
      }, 2000);
    });
  },
};
</script>
<style>
.amap-demo {
  height: 500px;
  width: 100%;
}
.prompt {
  background: white;
  width: 100px;
  height: 30px;
  text-align: center;
}
.search {
  float: left;
}
.lnglat {
  float: left;
  margin-left: 10px;
}
.map_bottom {
  padding-top: 20px;
}
.map_button {
  float: right;
}
</style>
